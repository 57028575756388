import React from 'react';

class DepthSelector extends React.Component {
  render() {
    const { name, selected, onChange } = this.props;
    return (
      <select
        name={name}
        value={selected}
        onChange={event => {
          onChange(event.target.value);
        }}
      >
        {this.renderOptions()}
      </select>
    );
  }

  renderOptions() {
    return this.props.depths.map(depth => {
      return (
        <option key={depth.id} value={depth.id}>
          {depth.name}
        </option>
      );
    });
  }
}

export default DepthSelector;
