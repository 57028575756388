class FieldbaseFieldLink {
  static fromJson(data) {
    if (!('id' in data)) {
      throw new Error(
        "FieldbaseFieldLink data missing required property: 'id'"
      );
    }

    if (!('fieldbase_account_id' in data)) {
      throw new Error(
        "FieldbaseFieldLink data missing required property: 'fieldbase_account_id'"
      );
    }

    return new FieldbaseFieldLink(data.id, data.fieldbase_account_id);
  }

  constructor(id, fieldbaseAccountId) {
    this.id = id;
    this.fieldbaseAccountId = fieldbaseAccountId;
    this.destroyed = false;
  }
}

export { FieldbaseFieldLink };
