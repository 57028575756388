import React from 'react';
import Select, { createFilter } from 'react-select';
import { FieldbaseFieldLink } from './fieldbase-field-link';

class FieldbaseFieldLinkSelector extends React.Component {
  constructor(props) {
    super(props);

    const { options, initial } = this.props;

    this._options = options.map(option => {
      return {
        value: option.id,
        label: this._fullNameFor(option),
      };
    });

    this._initialFieldLinks = initial.map(each => {
      let fieldLink = FieldbaseFieldLink.fromJson(each);

      // If this field link is no longer valid, i.e. is not included in the list of
      // options, then it must be destroyed.
      if (!this._options.find(each => each.value == fieldLink.fieldbaseAccountId)) {
        fieldLink.destroyed = true;
      }

      return fieldLink;
    });

    const initialFieldbaseAccountIds = this._initialFieldLinks.map(
      each => each.fieldbaseAccountId
    );
    this._initialValues = this._options.filter(each =>
      initialFieldbaseAccountIds.includes(each.value)
    );

    this.state = {
      selectedFieldLinks: this._initialFieldLinks,
    };
  }

  _fullNameFor = option => {
    return (option.client_path || [])
      .map(ancestor => ancestor.name)
      .concat(option.name || [])
      .join(': ');
  };

  _handleChange = selectedOptions => {
    this.setState(state => {
      const { selectedFieldLinks } = state;

      // Add new options to the state
      selectedOptions.forEach(option => {
        const id = option.value;

        let fieldLink = selectedFieldLinks.find(
          each => each.fieldbaseAccountId === id
        );
        if (!fieldLink) {
          fieldLink = new FieldbaseFieldLink(null, id);
          selectedFieldLinks.push(fieldLink);
        }
        fieldLink.destroyed = false;
      });

      // Remove un-selected options from the state
      const selectedIds = selectedOptions.map(each => each.value);
      for (let i = selectedFieldLinks.length - 1; i >= 0; i -= 1) {
        const fieldLink = selectedFieldLinks[i];
        if (!selectedIds.includes(fieldLink.fieldbaseAccountId)) {
          if (fieldLink.id) {
            fieldLink.destroyed = true;
          } else {
            selectedFieldLinks.splice(i, 1);
          }
        }
      }

      return { selectedFieldLinks };
    });
  };

  _renderInputsForFieldLink = (fieldLink, index) => {
    const baseName = `account[fieldbase_field_links_attributes][${index}]`;
    const idInputName = `${baseName}[id]`;
    const fieldbaseAccountIdInputName = `${baseName}[fieldbase_account_id]`;
    const destroyInputName = `${baseName}[_destroy]`;
    return (
      <React.Fragment key={baseName}>
        <input
          type="hidden"
          name={fieldbaseAccountIdInputName}
          value={fieldLink.fieldbaseAccountId}
        />
        {fieldLink.id !== null ? (
          <input type="hidden" name={idInputName} value={fieldLink.id} />
        ) : null}
        {fieldLink.id !== null && fieldLink.destroyed ? (
          <input type="hidden" name={destroyInputName} value />
        ) : null}
      </React.Fragment>
    );
  };

  _renderInputs = () => {
    const { selectedFieldLinks } = this.state;
    return selectedFieldLinks.map((each, index) =>
      this._renderInputsForFieldLink(each, index)
    );
  };

  render() {
    // By default the search happens on both label and value. We only care
    // about the label, here.
    const filterConfig = {
      ignoreCase: true,
      ignoreAccents: true,
      matchFrom: 'any',
      stringify: option => `${option.label}`,
      trim: true,
    };

    return (
      <React.Fragment>
        <Select
          placeholder="Select fieldbase accounts"
          defaultValue={this._initialValues}
          options={this._options}
          isClearable
          isSearchable
          isMulti
          filterOption={createFilter(filterConfig)}
          onChange={this._handleChange}
        />
        {this._renderInputs()}
      </React.Fragment>
    );
  }
}

export default FieldbaseFieldLinkSelector;
