import React from 'react';
import { Line } from 'react-chartjs-2';

export default class MultiScaleLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      data: {},
      options: {
        tooltips: {
          custom: function(tooltip) {
            if (!tooltip) return;
            // disable displaying the color box;
            tooltip.displayColors = false;
          },
          callbacks: {
            labelTextColor: function(tooltipItem, chart) {
              return chart.data.datasets[tooltipItem.datasetIndex].borderColor;
            },
            label: function(tooltipItem, data) {
              let labels = data.datasets[tooltipItem.datasetIndex].tooltips;
              let defaultLabel = `${
                data.datasets[tooltipItem.datasetIndex].label
              }: ${tooltipItem.yLabel}`;
              if (labels) {
                let label = [].concat(labels[tooltipItem.index], defaultLabel);
                return label;
              }

              return defaultLabel;
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: 'category',
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          ],
          yAxes: [],
        },
        title: {
          display: props.title,
          text: props.title,
        },
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    fetch(this.props.url)
      .then(res => res.json())
      .then(
        result => {
          this.createDatasets(result.data);
          this.setState({
            isLoaded: true,
          });
        },
        error => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  createDatasets(data) {
    const labels = data.labels;
    const chartJsDatasets = [];
    const yAxesOptions = {};
    data.datasets.map(dataset => {
      const { label, color, yAxisLabel, ...dataAndOptions } = dataset;
      chartJsDatasets.push({
        label: label,
        borderColor: color,
        yAxisID: yAxisLabel,
        fill: false,
        ...dataAndOptions,
      });
      yAxesOptions[yAxisLabel] = yAxesOptions[yAxisLabel] || {
        id: yAxisLabel,
        type: 'linear',
        position: 'left',
        scaleLabel: {
          labelString: yAxisLabel,
          display: true,
          fontColor: color,
          fontSize: 10,
        },
        ticks: {
          fontColor: color,
          fontSize: 14,
        },
        gridLines: {
          display: false,
        },
      };
      const chartJsData = {
        labels: labels,
        datasets: chartJsDatasets,
      };

      const options = { ...this.state.options };
      options.scales.yAxes = Object.values(yAxesOptions);

      this.setState({
        data: chartJsData,
        options: options,
      });
    });
  }

  render() {
    return (
      <div>
        <Line data={this.state.data} options={this.state.options} />
      </div>
    );
  }
}
