import React from 'react';
import PercentSlider from './PercentSlider';

const RecEditorConfigTool = props => (
  <div>
    <div className="row">
      <div className="col-sm-12 rec-col">
        <p>
          Apply surplus shown on Organic Acid extraction to Saturated Soil test
          of deficiencies according to the following scale:
        </p>
      </div>
    </div>
    <form>
      {props.elements.map((element, index) => (
        <div
          className="form-group row align-items-center justify-content-center mb-1"
          key={element.name}
        >
          <label
            className="col-sm-4 col-form-label rec-col"
            htmlFor={element.name}
          >
            {element.label}
          </label>
          <div className="col-sm-4 rec-col">
            {PercentSlider({
              className: 'form-control rec-col',
              id: element.name,
              onSlide: e => props.onSlide(e.target.value, element, index),
              ...element,
            })}
          </div>
          <div className="input-group input-group-sm col-sm-3 rec-col">
            <input
              className="form-control"
              type="text"
              value={Math.round(element.value * 100)}
              onChange={e => props.onChangeText(e.target.value, element, index)}
            />
            <div className="input-group-append">
              <div className="input-group-text">%</div>
            </div>
          </div>
        </div>
      ))}
    </form>
  </div>
);

export default RecEditorConfigTool;
