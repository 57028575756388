import React from 'react';

const Footer = props => (
  <React.Fragment>
    <button
      type="button"
      className="btn btn-secondary"
      data-dismiss="modal"
      onClick={props.onCancel}
    >
      {props.cancelText}
    </button>
    <button type="button" className="btn btn-primary" onClick={props.onOk}>
      {props.okText}
    </button>
  </React.Fragment>
);

export default Footer;
