import React from 'react';
import ReactDOM from 'react-dom';

class ModifierField extends React.Component {
  get field() {
    const { type, element, modifiers } = this.props;
    if (!modifiers[type]) {
      return '';
    }
    return modifiers[type][element] || '';
  }

  render() {
    const { type, element, e, updateUnsavedModifier } = this.props;
    return (
      <td>
        <input
          type="text"
          className="table-deficiency-modifiers--cell"
          value={this.field}
          name={element + '_std'}
          onChange={e => updateUnsavedModifier(type, element, e)}
        />
      </td>
    );
  }
}
export default ModifierField;
