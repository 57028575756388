import React from 'react';
import RecEditorModalButton from './RecEditorModal';

class ReactEditorModalButtonAngular extends React.Component {
  render() {
    return (
      <RecEditorModalButton onApply={this.applyToAngular} {...this.props} />
    );
  }

  applyToAngular(factors) {
    angular
      .element(jQuery('div[ng-app=recommendations]'))
      .injector()
      .get('RecommendationSamples')
      .updateSurplusFactors(factors);
  }
}

export default ReactEditorModalButtonAngular;
