import React from 'react';
import MappingRow from './mapping-row';

class VarietyMappingManager extends React.Component {
  constructor(props) {
    super(props);
    let nextNewIndex = 1;
    let mappings = props.mappings;
    mappings = mappings.map(mapping => {
      if (!mapping.serial) {
        const withTempId = { ...mapping, serial: `new-${nextNewIndex}` };
        nextNewIndex += 1;
        return withTempId;
      }
      return mapping;
    });
    if (mappings.length == 0) {
      mappings = [...mappings, { serial: 'new-1' }];
      nextNewIndex += 1;
    }
    this.state = {
      mappings: mappings,
      nextNewIndex: nextNewIndex,
    };
  }

  addMapping(event) {
    event.preventDefault();
    const index = this.state.nextNewIndex;
    this.setState({
      mappings: [
        ...this.state.mappings,
        { serial: `new-${this.state.nextNewIndex}` },
      ],
      nextNewIndex: index + 1,
    });
  }

  removeMapping(event, mappingId) {
    event.preventDefault();
    let newMappings = this.state.mappings.filter(mapping => mapping.serial != mappingId);
    if (newMappings.length == 0) {
      newMappings = [{ serial: 'new-1' }];
    }
    this.setState({ mappings: newMappings });
  }

  handleMappingChange(event, mappingId, attribute) {
    const updatedMappings = this.state.mappings.map(mapping => {
      if (mapping.serial === mappingId) {
        return { ...mapping, [attribute]: event.target.value };
      }
      return mapping;
    });
    this.setState({ mappings: updatedMappings });
  }

  render() {
    const { allCrops } = this.props;
    return (
      <form method="post" action={window.location.href}>
        <input type="hidden" name="_method" value="patch" />
        <div className="mapping-rows">
          {this.state.mappings.map(mapping => (
            <MappingRow
              key={mapping.serial}
              mapping={mapping}
              allCrops={allCrops}
              handleCropChange={(event, mappingId) =>
                this.handleMappingChange(event, mappingId, 'if2_crops_crop_id')
              }
              handleVarietyChange={(event, mappingId) =>
                this.handleMappingChange(
                  event,
                  mappingId,
                  'if2_crops_variety_id'
                )
              }
              removeMapping={(event, mappingId) =>
                this.removeMapping(event, mappingId)
              }
            />
          ))}
        </div>
        <p>
          <a href="#" onClick={event => this.addMapping(event)}>
            Add Mapping
          </a>
        </p>
        <p>
          <input type="submit" name="commit" value="Save" />
          <input type="submit" name="commit" value="Cancel" />
        </p>
      </form>
    );
  }
}

export default VarietyMappingManager;
