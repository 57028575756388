import React from 'react';

const Header = props => (
  <React.Fragment>
    <h5 className="modal-title h5">{props.title}</h5>
    <button
      type="button"
      className="close"
      data-dismiss="modal"
      onClick={props.onClose}
    >
      <span>&times;</span>
    </button>
  </React.Fragment>
);

export default Header;
