import React from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import DeficiencyRow from './DeficiencyRow';
import DeficiencyModifierModal from './DeficiencyModifierModal';
import * as _cloneDeep from 'lodash/cloneDeep';
import * as _round from 'lodash/round';

class DeficiencyModifierModalLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingModal: false,
      unsavedModifiers: { standard: {}, organic: {}, saturated: {} },
    };
    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    const { modifiers } = this.props;
    const standard = modifiers.standard || {};
    const organic = modifiers.organic || {};
    const saturated = modifiers.saturated || {};
    this.setState({ unsavedModifiers: { standard, organic, saturated } });
  }

  showModal() {
    this.setState({ isShowingModal: true });
  }

  closeModal = () => {
    this.setState({ isShowingModal: false });
  };

  updateUnsavedModifier(type, element, e) {
    const { unsavedModifiers } = this.state;
    const value =
      e.target.value === 0
        ? 0
        : (e.target.value && parseInt(e.target.value)) || '';
    if (!type || !element) {
      return;
    }
    const elements = unsavedModifiers[type];
    const updatedModifiers = {
      ...unsavedModifiers,
      [type]: { ...elements, [element]: value },
    };
    this.setState({ unsavedModifiers: updatedModifiers }, () =>
      console.log(this.state.unsavedModifiers)
    );
  }

  onSave = () => {
    const { onSave } = this.props;
    if (onSave) {
      return onSave(this.state.unsavedModifiers);
    }
    return fetch('/recommendations/save_deficiency_modifiers', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deficiency_modifiers: this.state.unsavedModifiers,
      }),
    });
  };

  render() {
    console.log(this.state.unsavedModifiers);
    const {
      deficiencies,
      updateUnsavedModifier,
      allUserDeficiencyModifiers,
    } = this.props;
    return (
      <div style={{ float: 'right' }}>
        <a onClick={this.showModal}>
          <img src="/images/cog.png" />
        </a>
        <DeficiencyModifierModal
          closeModal={this.closeModal}
          isShowingModal={this.state.isShowingModal}
          modifiers={this.state.unsavedModifiers}
          deficiencies={deficiencies}
          onSave={this.onSave}
          updateUnsavedModifier={(type, element, e) =>
            this.updateUnsavedModifier(type, element, e)
          }
          allUserDeficiencyModifiers={allUserDeficiencyModifiers}
        />
      </div>
    );
  }
}

export default DeficiencyModifierModalLink;
