import React from 'react';
import ReactDOM from 'react-dom';

class DeficiencyModifierFormHeader extends React.Component {
  render() {
    const {
      hasDeficiencyStandard,
      hasDeficiencyOrganic,
      hasDeficiencySaturated,
      allUserDeficiencyModifiers,
    } = this.props;
    return (
      <thead>
        <tr>
          <th />
          {hasDeficiencyStandard || allUserDeficiencyModifiers ? (
            <th>Std.</th>
          ) : null}
          {hasDeficiencyOrganic || allUserDeficiencyModifiers ? (
            <th>Org.</th>
          ) : null}
          {hasDeficiencySaturated || allUserDeficiencyModifiers ? (
            <th>Sat.</th>
          ) : null}
          <th />
        </tr>
      </thead>
    );
  }
}

export default DeficiencyModifierFormHeader;
