import React from 'react';
import Slider from './Slider';

const PercentSlider = props =>
  Slider({
    ...props,
    min: '0',
    max: '1',
    step: '0.01',
  });

export default PercentSlider;
