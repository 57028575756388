import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { TreeNodeContent } from '@miriamtech/if2-ui';
import { observable } from 'mobx';

@observer
class HighlightableTreeNodeContent extends Component {
  render() {
    const { nodeClasses, node, treeModel, ...props } = this.props;
    return (
      <TreeNodeContent
        {...props}
        node={node}
        nodeClasses={(nodeClasses || []).concat(
          node.id === treeModel.selectedAccount?.id ? 'selected-node' : []
        )}
      />
    );
  }
}

export default HighlightableTreeNodeContent;
