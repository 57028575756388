import React from 'react';
import ReactDOM from 'react-dom';
import * as _cloneDeep from 'lodash/cloneDeep';
import * as _round from 'lodash/round';

class DeficiencyRow extends React.Component {
  render() {
    const {
      element,
      labelSuffix,
      rowClassName,
      precision,
      deficiencies,
      applied,
    } = this.props;
    var className = 'nutrient';
    if (this.props.rowClassName) {
      className += ' ' + this.props.rowClassName;
    }
    var elementLabel = element;
    if (labelSuffix) {
      elementLabel += labelSuffix;
    }
    const deficiencyKeys = Object.keys(deficiencies);
    return (
      <tr key={element} className={className}>
        <th>{elementLabel}</th>
        {deficiencyKeys.map(each =>
          this.renderDeficiency(each, deficiencies[each], element, precision)
        )}
        <td className="text-right">{_round(applied, 2)}</td>
      </tr>
    );
  }

  renderDeficiency(type, deficiency, element, precision) {
    return (
      <td key={type} className="text-center">
        {this.deficiency(type, deficiency, element, precision)}
      </td>
    );
  }

  deficiency(type, deficiency, element, precision) {
    if (deficiency == null) return null;
    const { modifiers } = this.props;
    var modifierPercentage = modifiers.saved[type][element];
    if (!modifierPercentage) {
      modifierPercentage = 100;
    }
    const rounded = _round(
      deficiency * (modifierPercentage / 100),
      precision ? precision : 0
    );
    return rounded <= 0 ? '' : rounded;
  }
}

export default DeficiencyRow;
