import React from 'react';

class PropObserver extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    const { observedProp, onPropChanged } = this.props;
    if (prevProps.observedProp != observedProp) {
      onPropChanged(prevProps.observedProp, observedProp);
    }
  }

  // Side-effect-only component.  Nothing to render
  render() {
    return null;
  }
}

export default PropObserver;
