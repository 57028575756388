import React from 'react';
import PropObserver from './PropObserver';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnimatingShow: false,
      isAnimatingHide: false,
      isDisplayed: false,
      hasShowClass: false,
    };
  }

  showModal() {
    if (this.state.isAnimatingShow) {
      return;
    }
    this.setState({ isDisplayed: true, isAnimatingShow: true });
    setTimeout(
      () =>
        this.setState({
          hasShowClass: true,
          isAnimatingShow: false,
        }),
      1
    );
  }

  hideModal() {
    if (this.state.isAnimatingHide) {
      return;
    }
    this.setState({ hasShowClass: false, isAnimatingHide: true });
    setTimeout(
      () =>
        this.setState({
          isDisplayed: false,
          isAnimatingHide: false,
        }),
      500
    );
  }

  isShowingChanged() {
    // Consumer changed isShowing to `true`
    if (!this.state.isAnimatingShow && this.props.isShowing) {
      this.showModal();
    }

    // Consumer changed isShowing to `false`
    if (!this.state.isAnimatingHide && !this.props.isShowing) {
      this.hideModal();
    }
  }

  getShowClass() {
    return this.state.hasShowClass ? ' show' : '';
  }

  getDisplayValue() {
    return this.state.isDisplayed ? 'block' : 'none';
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={`modal fade${this.getShowClass()}`}
          style={{ display: this.getDisplayValue() }}
          tabIndex="-1"
          role="dialog"
          id="rec-modal"
        >
          <PropObserver
            observedProp={this.props.isShowing}
            onPropChanged={() => this.isShowingChanged()}
          />
          <div
            className={`modal-backdrop fade${this.getShowClass()}`}
            style={{ display: this.getDisplayValue(), zIndex: 'auto' }}
            onClick={this.props.onBackdropClick}
          />
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">{this.props.header}</div>
              <div className="modal-body">{this.props.body}</div>
              <div className="modal-footer">{this.props.footer}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Modal;
