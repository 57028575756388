import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import AccountsStore from '../../stores/accounts-store';
import FieldsTable from './FieldsTable';
import { Tree, Spinner } from '@miriamtech/if2-ui';
import HighlightableTreeNodeContent from './HighlightableTreeNodeContent';
import AccountReportsTreeModel from './TreeModel';

@observer
class AccountReportsSelector extends Component {
  @observable
  fields;

  @observable
  fieldsLoading = false;

  constructor(props) {
    super(props);
    this.accountsStore = new AccountsStore();
    props.roots.forEach(root => this.accountsStore.push(root));
    this.treeModel = new AccountReportsTreeModel(this.accountsStore);
    if (props.previously_selected_account) {
      this.treeModel
        .openToAccounts([props.previously_selected_account.id])
        .then(() => this.selectAccount(props.previously_selected_account.id));
    }
  }

  @action
  selectAccount = accountId => {
    const account = this.accountsStore.find(accountId);
    this.treeModel.selectedAccount = account;
    this.fieldsLoading = true;
    this.accountsStore
      .fetchHierarchyFieldsOf(accountId)
      .then(fields => (this.fields = fields))
      .finally(() => (this.fieldsLoading = false))
      .catch(error => console.error(error));
  };

  renderFields() {
    return this.fields.length > 0 ? (
      <FieldsTable fields={this.fields} />
    ) : (
      <em>{this.treeModel.selectedAccount.name} doesn't have any fields</em>
    );
  }

  renderSelectedAccount() {
    if (!this.treeModel.selectedAccount) return null;
    return (
      <div>
        <h2>{this.treeModel.selectedAccount.name}</h2>
        {this.fieldsLoading ? <Spinner /> : this.renderFields()}
      </div>
    );
  }

  render() {
    return (
      <div className="account-report-selector">
        <div className="d-flex">
          <div className="p-2 account-selector-sidebar bg-light">
            <Tree
              model={this.treeModel}
              items={this.accountsStore.roots}
              ContentComponent={HighlightableTreeNodeContent}
              actions={{
                handleClickNodeContent: this.selectAccount,
              }}
            />
          </div>
          <div className="selected-account p-2 flex-grow-1">
            {this.renderSelectedAccount()}
          </div>
        </div>
      </div>
    );
  }
}

export default AccountReportsSelector;
