import React from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import DeficiencyRow from './DeficiencyRow';
import DeficiencyModifierModalLink from './DeficiencyModifierModalLink';
import * as _cloneDeep from 'lodash/cloneDeep';
import * as _round from 'lodash/round';

class RecEditorDeficiencySidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowingModal: false,
      deficiencies: {},
      modifiers: {},
    };
    this.state.modifiers.saved = this.initializeModifierState(props.modifiers);
    this.state.modifiers.unsaved = this.initializeModifierState(
      props.modifiers
    );
  }

  fleshedOutModifiers(modifiers) {
    var toFlesh = _cloneDeep(modifiers);
    if (!toFlesh) {
      toFlesh = {};
    }
    ['standard', 'organic', 'saturated'].forEach(type => {
      if (!toFlesh[type]) {
        toFlesh[type] = {};
      }
      let elements = this.poundsPerAcreElements().concat(
        this.micronutrientElements()
      );
      elements.forEach(element => {
        if (!toFlesh[type][element]) {
          toFlesh[type][element] = '';
        }
      });
    });
    return toFlesh;
  }

  initializeModifierState() {
    fetch('/recommendations/user_deficiency_modifiers')
      .then(res => res.json())
      .then(res => {
        let modifiers = this.fleshedOutModifiers(res);
        this.setState({
          modifiers: {
            saved: modifiers,
            unsaved: modifiers,
          },
        });
      });
  }

  componentDidMount() {
    this.fetchDeficiencies();
  }

  fetchDeficiencies() {
    const { sampleIDs } = this.props;
    const idParam = (sampleIDs || []).join('-');
    fetch(`/recommendations/deficiencies/${idParam}`).then(response => {
      if (response.ok) {
        response.json().then(result => {
          this.setState({ deficiencies: result });
        });
      }
    });
  }

  hasAnyDeficiencies() {
    return Object.keys(this.state.deficiencies).any(
      type => Object.keys(this.state.deficiencies[type]).length > 0
    );
  }

  applied(element) {
    let value = (this.props.applied || {})[element];
    if (value == null) {
      return 0;
    }
    return value;
  }

  hasDeficiency(type) {
    return (
      this.state.deficiencies[type] &&
      Object.keys(this.state.deficiencies[type]).length > 0
    );
  }

  deficiencyCount() {
    var count = 0;
    if (this.hasDeficiency('standard')) count++;
    if (this.hasDeficiency('organic')) count++;
    if (this.hasDeficiency('saturated')) count++;
    return count;
  }

  deficiencyColumnCount() {
    return this.deficiencyCount() == 0 ? 1 : this.deficiencyCount();
  }

  renderHeader(type, name) {
    if (this.hasDeficiency(type)) {
      return <th style={{ textAlign: 'center' }}>{name}</th>;
    }
  }

  renderTypeHeaders() {
    if (this.deficiencyColumnCount() < 2) return null;
    return (
      <tr
        className="header"
        style={{ fontSize: 'smaller', fontWeight: 'normal' }}
      >
        <th />
        {this.renderHeader('standard', 'Std.')}
        {this.renderHeader('organic', 'Org.')}
        {this.renderHeader('saturated', 'Sat.')}
        <th />
      </tr>
    );
  }

  deficiencies(key) {
    var result = {};
    ['standard', 'organic', 'saturated'].forEach(type => {
      if (this.hasDeficiency(type)) {
        result[type] = this.state.deficiencies[type][key];
      }
    });
    return result;
  }

  renderTable(header, elements, options = {}) {
    return (
      <div>
        <h3>{header}</h3>
        <table
          id="deficiency-sidebar"
          className="grid"
          style={{ marginTop: '1em' }}
        >
          <tbody>
            <tr className="header">
              <th />
              {this.hasAnyDeficiencies() ? (
                <th
                  className="column"
                  colSpan={this.deficiencyColumnCount()}
                  style={{ textAlign: 'center' }}
                >
                  Deficiency
                </th>
              ) : null}
              <th className="column" style={{ textAlign: 'right' }}>
                Applied
              </th>
            </tr>
            {this.renderTypeHeaders()}
            {elements.map((element, i) => {
              var elementSymbol = element.toLowerCase();
              if (elementSymbol == 'k') elementSymbol = 'k2o';
              if (elementSymbol == 'p') elementSymbol = 'p2o5';
              return (
                <DeficiencyRow
                  key={element}
                  element={element}
                  labelSuffix={options.labelSuffix}
                  rowClassName={i % 2 == 0 ? '' : 'odd'}
                  precision={options.precision}
                  deficiencies={this.deficiencies(elementSymbol)}
                  modifiers={this.state.modifiers}
                  applied={this.applied(element)}
                />
              );
            })}
            <tr className="border">
              <td colSpan={this.deficiencyColumnCount() + 2} />
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  poundsPerAcreElements() {
    return ['N', 'P', 'K', 'S', 'Ca', 'Mg'];
  }

  micronutrientElements() {
    return ['B', 'Fe', 'Mn', 'Cu', 'Zn'];
  }

  renderModalLink = () => {
    return (
      <DeficiencyModifierModalLink
        modifiers={this.state.modifiers.saved}
        deficiencies={this.state.deficiencies}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        {this.deficiencyCount() > 0 ? this.renderModalLink() : ''}
        {this.renderTable('Total lbs/acre', this.poundsPerAcreElements())}
        {this.renderTable('Micronutrients', this.micronutrientElements(), {
          labelSuffix: this.hasAnyDeficiencies() ? '*' : '',
          precision: 2,
        })}
        {this.hasAnyDeficiencies() ? (
          <p className="fine-print">
            * Refer to tissue/fruit analysis to confirm micronutrient
            deficiencies.
          </p>
        ) : null}
      </React.Fragment>
    );
  }
}

export default RecEditorDeficiencySidebar;
