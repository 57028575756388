import React from 'react';

const Slider = props => {
  const { onSlide, ...remainingProps } = props;
  return React.createElement('input', {
    ...remainingProps,
    type: 'range',
    onChange: onSlide,
  });
};

export default Slider;
