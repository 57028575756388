import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class FieldsTable extends Component {
  render() {
    const { fields } = this.props;
    return (
      <table className="table fields-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Acres</th>
            <th>Crop/Variety</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {fields.map(field => {
            const href = `./field_selector/report/${field.id}`;
            return (
              <tr key={field.id}>
                <td>
                  {field.account.name}
                  <h3>
                    <a href={href}>{field.name}</a>
                  </h3>
                </td>
                <td>{field.area}</td>
                <td>{field.crop}</td>
                <td>
                  <a href={href} className="btn btn-primary btn-sm">
                    View
                  </a>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}

export default FieldsTable;
