import React from 'react';

class MappingRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allVarietiesByCrop: {},
    };
  }

  componentDidMount() {
    const { mapping } = this.props;
    if (mapping.if2_crops_crop_id) {
      this.loadVarieties(mapping.if2_crops_crop_id);
    }
  }

  handleCropChange(event, mappingId) {
    const { handleCropChange } = this.props;
    const selectedCropId = event.target.value;
    if (selectedCropId) {
      this.loadVarieties(selectedCropId);
      handleCropChange(event, mappingId);
    } else {
      this.setState({ allVarieties: [] });
    }
  }

  loadVarieties(cropId) {
    fetch(`/if2/api/v2/crops/${cropId}/varieties`)
      .then(response => response.json())
      .then(varieties => {
        let allVarietiesByCrop = {
          ...this.state.allVarietiesByCrop,
          [cropId]: varieties,
        };
        this.setState({ allVarietiesByCrop: allVarietiesByCrop });
      })
      .catch(error => console.error(error));
  }

  render() {
    const {
      mapping,
      allCrops,
      handleVarietyChange,
      removeMapping,
    } = this.props;
    const varieties = this.state.allVarietiesByCrop[mapping.if2_crops_crop_id];
    return (
      <>
        <div className="mapping-col">
          <select
            name={`variety[if2_variety_mappings][${
              mapping.serial
            }][if2_crops_crop_id]`}
            onChange={event => this.handleCropChange(event, mapping.serial)}
            value={mapping.if2_crops_crop_id || ''}
          >
            <option key={''} />
            {allCrops.map(crop => (
              <option key={crop.id} value={crop.id}>
                {crop.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mapping-col">
          {!varieties || varieties.length == 0 ? null : (
            <select
              name={`variety[if2_variety_mappings][${
                mapping.serial
              }][if2_crops_variety_id]`}
              onChange={event => handleVarietyChange(event, mapping.serial)}
              value={mapping.if2_crops_variety_id || ''}
            >
              <option key={''} />
              {varieties
                .sort((v1, v2) => v1.name.localeCompare(v2.name))
                .map(variety => (
                  <option key={variety.id} value={variety.id}>
                    {variety.name}
                  </option>
                ))}
            </select>
          )}
        </div>
        <div className="mapping-col">
          <a href="#" onClick={event => removeMapping(event, mapping.serial)}>
            <img src="/images/delete.png" />
          </a>
        </div>
      </>
    );
  }
}

export default MappingRow;
