import React from 'react';
import ReactDOM from 'react-dom';
import Octicon, { Gear } from '@githubprimer/octicons-react';
import RecEditorConfigTool from './RecEditorConfigTool';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';

class RecEditorModalButton extends React.Component {
  constructor(props) {
    super(props);

    const { surplus_ca = 0, surplus_mg = 0, surplus_k = 0 } = props;

    this.state = {
      defaultCa: surplus_ca,
      defaultMg: surplus_mg,
      defaultK: surplus_k,
      isShowing: false,
    };

    this.state.elements = this.getDefaultElements();
    this.state.lastElementState = this.state.elements;
  }

  resetElementValues() {
    this.setState(state => ({ elements: state.lastElementState }));
  }

  recordElementState() {
    this.setState(state => ({ lastElementState: state.elements }));
  }

  getDefaultElements() {
    return [
      {
        value: this.state.defaultCa,
        label: 'Calcium (Ca):',
        name: 'surplus_ca',
      },
      {
        value: this.state.defaultMg,
        label: 'Magnesium (Mg):',
        name: 'surplus_mg',
      },
      {
        value: this.state.defaultK,
        label: 'Potassium (K):',
        name: 'surplus_k',
      },
    ];
  }

  renderModal() {
    ReactDOM.render(
      <Modal
        isShowing={this.state.isShowing}
        onBackdropClick={() => this.handleCancel()}
        header={ModalHeader({
          title: 'Configuration',
          onClose: () => this.handleCancel(),
        })}
        body={RecEditorConfigTool({
          elements: this.state.elements,
          onSlide: (value, element, index) => {
            this.updateElementValue(value, element, index);
          },
          onChangeText: (value, element, index) => {
            this.handleTextInput(value, element, index);
          },
        })}
        footer={ModalFooter({
          cancelText: 'Cancel',
          okText: 'Apply',
          onCancel: () => this.handleCancel(),
          onOk: () => this.handleApply(),
        })}
      />,
      document.getElementById('rec-editor-modal-container')
    );
  }

  componentDidMount() {
    this.resetElementValues();
    this.renderModal();
  }

  componentDidUpdate() {
    this.renderModal();
  }

  updateElementValue(value, element, index) {
    this.setState(state => {
      const newElements = state.elements.slice();
      newElements[index] = { ...element, value };
      return { elements: newElements };
    });
  }

  handleTextInput(value, element, index) {
    let numericVal = Number.isNaN(Number(value)) ? 0 : Number(value);
    numericVal = numericVal > 100 ? 100 : numericVal;
    this.updateElementValue(numericVal / 100, element, index);
  }

  handleShowModal() {
    this.setState({ isShowing: true });
  }

  handleHideModal() {
    this.setState({ isShowing: false });
  }

  getFactors() {
    return this.state.elements.reduce((acc, currentValue) => {
      acc[currentValue.name] = currentValue.value;
      return acc;
    }, {});
  }

  handleApply() {
    this.handleHideModal();
    this.recordElementState();
    this.props.onApply(this.getFactors());
  }

  handleCancel() {
    this.handleHideModal();
    this.resetElementValues();
  }

  render() {
    return (
      <div className="row justify-content-end">
        <span
          onClick={() => {
            this.handleShowModal();
          }}
        >
          <Octicon className="rec-icon" icon={Gear} />
        </span>
      </div>
    );
  }
}

export default RecEditorModalButton;
