import React from 'react';
import Modal from './Modal';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import ModifierField from './ModifierField';
import DeficiencyModifierFormHeader from './DeficiencyModifierFormHeader';

class DeficiencyModifierModal extends React.Component {
  static poundsPerAcreElements = [
    'N',
    'P',
    'K',
    'S',
    'Ca',
    'Mg',
    'K2O',
    'P2O5',
  ];

  static micronutrientElements = ['B', 'Fe', 'Mn', 'Cu', 'Zn'];

  onSave = () => {
    const { onSave } = this.props;
    onSave();
  };

  renderDeficiencyModifierForm() {
    const { allUserDeficiencyModifiers } = this.props;
    return (
      <table className="table-deficiency-modifiers">
        <DeficiencyModifierFormHeader
          hasDeficiencyStandard={this.hasDeficiency('standard')}
          hasDeficiencyOrganic={this.hasDeficiency('organic')}
          hasDeficiencySaturated={this.hasDeficiency('saturated')}
          allUserDeficiencyModifiers={allUserDeficiencyModifiers}
        />
        <tbody>
          {this.renderModifierFieldRows(this.constructor.poundsPerAcreElements)}
          {this.renderModifierFieldRows(this.constructor.micronutrientElements)}
        </tbody>
      </table>
    );
  }

  renderModifierFieldRows(elements) {
    const {
      modifiers,
      updateUnsavedModifier,
      allUserDeficiencyModifiers,
    } = this.props;
    return (
      <>
        {elements.map(element => (
          <tr key={element}>
            <th>{element}</th>
            {this.hasDeficiency('standard') || allUserDeficiencyModifiers ? (
              <ModifierField
                type="standard"
                element={element}
                modifiers={modifiers}
                updateUnsavedModifier={updateUnsavedModifier}
              />
            ) : null}
            {this.hasDeficiency('organic') || allUserDeficiencyModifiers ? (
              <ModifierField
                type="organic"
                element={element}
                modifiers={modifiers}
                updateUnsavedModifier={updateUnsavedModifier}
              />
            ) : null}
            {this.hasDeficiency('saturated') || allUserDeficiencyModifiers ? (
              <ModifierField
                type="saturated"
                element={element}
                modifiers={modifiers}
                updateUnsavedModifier={updateUnsavedModifier}
              />
            ) : null}
            <td>%</td>
          </tr>
        ))}
      </>
    );
  }

  hasDeficiency(type) {
    const { deficiencies } = this.props;
    const d = deficiencies || {};
    return d[type] && Object.keys(d[type]).length > 0;
  }

  render() {
    const { closeModal, isShowingModal } = this.props;
    return (
      <Modal
        isShowing={isShowingModal}
        onBackdropClick={closeModal}
        header={ModalHeader({
          title: 'Deficiency Modifiers',
          onClose: closeModal,
        })}
        body={this.renderDeficiencyModifierForm()}
        footer={ModalFooter({
          cancelText: 'Cancel',
          okText: 'Save',
          onCancel: closeModal,
          onOk: () => {
            this.onSave();
            closeModal();
          },
        })}
      />
    );
  }
}

export default DeficiencyModifierModal;
