import React from 'react';
import DepthSelector from './DepthSelector';

class DepthMultiSelector extends React.Component {
  constructor(props) {
    super(props);

    const { samples } = props;

    this.state = {
      depthBySample: {},
    };
    samples.map(each => {
      this.state.depthBySample[each.id] = each.depth_id;
    });
  }

  depthWithId(depthId) {
    return this.props.depths.find(each => {
      each.id == depthId;
    });
  }

  setDepth(sampleId, depthId) {
    this.setState(state => {
      state.depthBySample[sampleId] = depthId;
      return state;
    });
  }

  commonDepthId() {
    const { samples } = this.props;
    const { depthBySample } = this.state;
    var test = samples[0].id;
    var common = depthBySample[test];
    if (
      samples.every(each => {
        return depthBySample[each.id] == common;
      })
    ) {
      return common;
    } else {
      return '';
    }
  }

  setCommonDepth(depthId) {
    this.setState(state => {
      this.props.samples.map(sample => {
        state.depthBySample[sample.id] = depthId;
      });
      return state;
    });
  }

  render() {
    return (
      <div>
        {this.renderCommonSelector()}
        <table>
          <tbody>
            <tr>
              <th>Sample</th>
              <th>Depth</th>
            </tr>
            {this.renderSamples()}
          </tbody>
        </table>
      </div>
    );
  }

  renderCommonSelector() {
    return (
      <p>
        Set all depths to:&nbsp;
        <DepthSelector
          name="all"
          depths={[{ id: '', name: '' }].concat(this.props.depths)}
          selected={this.commonDepthId()}
          onChange={depth => {
            this.setCommonDepth(depth);
          }}
        />
      </p>
    );
  }

  renderSamples() {
    return this.props.samples.map(sample => {
      return (
        <tr key={sample.id}>
          <td>
            <img
              src={this.props.icon}
              style={{ width: '12px', height: '12px' }}
            />
            &nbsp;
            {sample.identification}
          </td>
          <td>
            <DepthSelector
              name={`samples[${sample.id}][depth_id]`}
              depths={this.props.depths}
              selected={this.state.depthBySample[sample.id]}
              onChange={depth => {
                this.setDepth(sample.id, depth);
              }}
            />
          </td>
        </tr>
      );
    });
  }
}

export default DepthMultiSelector;
