import { observable, computed, action, runInAction } from 'mobx';
import { computedFn } from 'mobx-utils';
import { loadingStatusCollectionMixin, Mix } from '@miriamtech/if2-js-model';

export default class AccountsStore extends Mix(loadingStatusCollectionMixin) {
  apiPath = '/api/accounts';

  @observable
  accountsMap = new Map();

  @observable
  isLoading = false;

  @computed
  get roots() {
    return [...this.accountsMap.values()].filter(account => !account.parent);
  }

  destroy() {
    this.disposeLocalStoreCache();
  }

  find = id => {
    return this.accountsMap.get(id);
  };

  findMany(ids) {
    return ids.map(this.find).filter(Boolean);
  }

  @action
  push(account) {
    let storedAccount = this.find(account.id);
    if (!storedAccount) {
      this.accountsMap.set(account.id, account);
      storedAccount = this.accountsMap.get(account.id);
      this.ensureChildrenAreStoredCopies(account.id);
    } else if (
      storedAccount.children.length === 0 &&
      account.children.length > 0
    ) {
      storedAccount.children = account.children;
      this.ensureChildrenAreStoredCopies(account.id);
    }
    this.setLoaded(account.id, true);
    return storedAccount;
  }

  @action
  ensureChildrenAreStoredCopies(accountId) {
    const storedAccount = this.find(accountId);
    if (storedAccount.children.length === 0) return;
    storedAccount.children = storedAccount.children.map(child =>
      this.push(child)
    );
  }

  @action
  load(id) {
    this.isLoading = true;
    this.setPending(id, true);
    return this.fetch(id)
      .then(doc => {
        if (doc) {
          this.finishPending(id);
          return this.push(doc);
        }
        return doc;
      })
      .finally(() =>
        runInAction(() => {
          this.setPending(id, false);
          this.isLoading = false;
        })
      );
  }

  @action
  updateChildrenOf(id, options = { setPending: true }) {
    const account = this.find(id);
    if (!account) return;
    if (options?.setPending) this.setPending(id, true);
    return this.fetch(`${id}/children`)
      .then(children =>
        runInAction(() => {
          account.children = children;
          this.ensureChildrenAreStoredCopies(account.id);
        })
      )
      .catch(error =>
        runInAction(() => {
          account.lastError = error;
        })
      )
      .finally(() => {
        runInAction(() => {
          if (options?.setPending) this.setPending(id, false);
        });
      });
  }

  @action
  loadAncestorsWithContextOf(id) {
    this.isLoading = true;
    return this.fetchAncestorsWithContextOf(id)
      .then(accounts => {
        return accounts.forEach(account => this.push(account));
      })
      .then(
        runInAction(() => {
          this.hasLoadedAncestorsWithContext = true;
        })
      )
      .finally(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
  }

  fetchAncestorsWithContextOf(accountId) {
    return this.fetch(`${accountId}/ancestors_with_context`);
  }

  fetchHierarchyFieldsOf(accountId) {
    return this.fetch(`${accountId}/fields`);
  }

  fetch(path) {
    return fetch(`${this.apiPath}/${path}`.replace('//', '/')).then(response =>
      response.json()
    );
  }
}
